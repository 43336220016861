import { API_URL, Persistence } from '@absdepot/data';
import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { defer, firstValueFrom, Observable } from 'rxjs';
import { BYPASS_APPEND_AUTH_TOKEN } from './append-auth-token.interceptor';
import { AuthStatus } from './auth-status';
import { TOKEN_STORAGE_KEY } from './constants';
import {
  ChangePasswordRequestBody,
  ExchangeFirebaseTokenRequestBody,
  LinkFirebaseAccountRequestBody,
  LoginResponsePayload,
  LoginWithCredentialsRequestBody,
  RegisterRequestBody,
  ResetPasswordRequestBody,
} from './interfaces';

const bypassTokenOptions = () => ({
  context: new HttpContext().set(BYPASS_APPEND_AUTH_TOKEN, true),
});

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly #http = inject(HttpClient);
  readonly #storage = inject(Persistence);

  protected readonly _status = signal<AuthStatus>(AuthStatus.Waiting);

  readonly apiUrl = inject(API_URL);
  readonly status = this._status.asReadonly();
  readonly token = signal<string | null | undefined>(undefined);

  protected async authenticate(command: Observable<{ token: string }>) {
    this._status.set(AuthStatus.Loading);
    try {
      const { token } = await firstValueFrom(command);
      await this.#storage.set({ key: TOKEN_STORAGE_KEY, value: token });
      this.token.set(token);
      this._status.set(AuthStatus.Authenticated);
    } catch (e) {
      this._status.set(AuthStatus.Error);
      throw e;
    }
  }

  protected async checkToken(token: string) {
    const request = this.#http.get(`${this.apiUrl}/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
      ...bypassTokenOptions(),
    });
    try {
      await firstValueFrom(request);
      return true;
    } catch {
      return false;
    }
  }

  async boot() {
    await this.syncStateFromStorage();
  }

  async changePassword(data: ChangePasswordRequestBody) {
    const url = `${this.apiUrl}/auth/change-password`;
    const command = this.#http.post<{ message: string }>(url, data);
    return firstValueFrom(command);
  }

  async forgotPassword(email: string) {
    const url = `${this.apiUrl}/auth/forgot-password`;
    const command = this.#http.post<{ message: string }>(
      url,
      { email },
      bypassTokenOptions(),
    );
    return firstValueFrom(command);
  }

  async linkFirebaseAccount(data: LinkFirebaseAccountRequestBody) {
    const url = `${this.apiUrl}/auth/firebase/link`;
    const request = this.#http.post<{ message: string }>(url, data);
    return firstValueFrom(request);
  }

  async loginWithFirebase(data: ExchangeFirebaseTokenRequestBody) {
    const url = `${this.apiUrl}/auth/firebase/session`;
    const command = this.#http.post<LoginResponsePayload>(
      url,
      JSON.parse(JSON.stringify(data)),
      bypassTokenOptions(),
    );
    await this.authenticate(command);
  }

  async logout() {
    this._status.set(AuthStatus.Loading);
    const command = this.#http.post<void>(`${this.apiUrl}/auth/logout`, null);
    try {
      await Promise.all([
        this.#storage.remove({ key: TOKEN_STORAGE_KEY }),
        firstValueFrom(command),
      ]);
    } finally {
    }
    this._status.set(AuthStatus.NotAuthenticated);
    this.token.set(null);
  }

  async loginWithCredentials(credentials: LoginWithCredentialsRequestBody) {
    const url = `${this.apiUrl}/auth/login`;
    const command = defer(() =>
      this.#http.post<LoginResponsePayload>(
        url,
        credentials,
        bypassTokenOptions(),
      ),
    );
    await this.authenticate(command);
  }

  async register(data: RegisterRequestBody) {
    const url = `${this.apiUrl}/auth/register`;
    const command = defer(() =>
      this.#http.post<LoginResponsePayload>(url, data, bypassTokenOptions()),
    );
    await this.authenticate(command);
  }

  async resetPassword(data: ResetPasswordRequestBody) {
    const url = `${this.apiUrl}/auth/reset-password`;
    const command = defer(() =>
      this.#http.post<{ token: string }>(url, data, bypassTokenOptions()),
    );
    await this.authenticate(command);
  }

  async syncStateFromStorage() {
    const { value: persistedToken } = await this.#storage.get({
      key: TOKEN_STORAGE_KEY,
    });

    if (!persistedToken) {
      this._status.set(AuthStatus.NotAuthenticated);
      return;
    }

    const isTokenValid = await this.checkToken(persistedToken);

    if (!isTokenValid) {
      this._status.set(AuthStatus.NotAuthenticated);
      await this.#storage.remove({ key: TOKEN_STORAGE_KEY });
      return;
    }

    this.token.set(persistedToken);
    this._status.set(AuthStatus.Authenticated);
  }
}
