export const NCF_TYPES = [
  'e-CF',
  '01',
  '02',
  '03',
  '04',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
] as const;

export type NCFType = (typeof NCF_TYPES)[number];

export const NCF_TYPES_LABELS: Record<NCFType, string> = {
  'e-CF': 'Comprobante Fiscal Electrónico',
  '01': 'Factura de Crédito Fiscal',
  '02': 'Factura de Consumo',
  '03': 'Notas de Débito',
  '04': 'Notas de Crédito',
  '11': 'Comprobante de Compras',
  '12': 'Comprobante de Registro Único de Ingresos',
  '13': 'Comprobante para Gastos Menores',
  '14': 'Comprobante para Regímenes Especiales',
  '15': 'Comprobante Gubernamental',
  '16': 'Comprobante para Exportaciones',
  '17': 'Comprobante para Pagos al Exterior',
};
