import { Model } from './model';
import { Transaction } from './transaction';
import { User } from './user';
import { Wallet } from './wallet';

export type WalletTransactionType =
  | 'purchase'
  | 'refund'
  | 'adjustment'
  | 'reward';

export interface WalletTransaction extends Model {
  user_wallet_id: Wallet['id'];
  type: WalletTransactionType;
  description: string;
  amount: number;
  reference_id?: Transaction['id'];

  reference?: Transaction;
  user?: User;
  wallet?: Wallet;
}

const labels: { [key in WalletTransactionType]: string } = {
  purchase: 'Compra',
  refund: 'Reembolso',
  adjustment: 'Ajuste',
  reward: 'Recompensa',
};

export const label = (type: WalletTransactionType) => labels[type];
