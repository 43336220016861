export interface PaymentProfile {
  PaymentProfileId: number;
  PaymentMediaId: number;
  Brand: string;
  CardOwner: string;
  Bin: string;
  IssuerBank: unknown;
  Type: string;
  Token: string;
  Expiration: string;
  Last4: string;
  Enabled: boolean;
}

export function isPaymentProfile(value: any): value is PaymentProfile {
  return (
    typeof value === 'object' &&
    value !== null &&
    typeof value.PaymentProfileId === 'number' &&
    typeof value.PaymentMediaId === 'number' &&
    typeof value.Brand === 'string' &&
    typeof value.CardOwner === 'string' &&
    typeof value.Bin === 'string' &&
    // IssuerBank is unknown so no need to check
    typeof value.Type === 'string' &&
    typeof value.Token === 'string' &&
    typeof value.Expiration === 'string' &&
    typeof value.Last4 === 'string' &&
    typeof value.Enabled === 'boolean'
  );
}
