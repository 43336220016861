import { InjectionToken } from '@angular/core';

export const API_URL = new InjectionToken<string>('api url');

export const provideApiUrl = (value: string) => {
  return {
    provide: API_URL,
    useValue: value,
  };
};
