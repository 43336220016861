import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AuthStatus } from './auth-status';
import { AuthenticationService } from './authentication.service';

export const isAuthenticated =
  (redirect: any[] = ['/auth']) =>
  () => {
    const auth = inject(AuthenticationService);
    const router = inject(Router);

    return toObservable(auth.status).pipe(
      filter(
        (status) =>
          status !== AuthStatus.Loading && status !== AuthStatus.Waiting,
      ),
      map(
        (status) =>
          status === AuthStatus.Authenticated || router.createUrlTree(redirect),
      ),
    );
  };
