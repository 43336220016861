import { CustomerGroupMembership } from './customer-group-membership';
import { PaymentProfile } from './payment-profile';
import { Wallet } from './wallet';

export type PaymentMethodType =
  | 'card'
  | 'bank_transfer'
  | 'customer_group_membership_credit'
  | 'wallet_balance';

export interface BasePaymentMethod {
  amount: number;
  type: PaymentMethodType;
}

export interface CardPaymentMethod extends BasePaymentMethod {
  type: 'card';
  token: PaymentProfile['Token'];
}

export interface BankTransferPaymentMethod extends BasePaymentMethod {
  type: 'bank_transfer';
  proof: File;
}

export interface CustomerGroupMembershipCreditPaymentMethod
  extends BasePaymentMethod {
  type: 'customer_group_membership_credit';
  membership_id: CustomerGroupMembership['id'];
}

export interface WalletBalancePaymentMethod extends BasePaymentMethod {
  type: 'wallet_balance';
  wallet_id: Wallet['id'];
}

export type PaymentMethod =
  | CardPaymentMethod
  | BankTransferPaymentMethod
  | CustomerGroupMembershipCreditPaymentMethod
  | WalletBalancePaymentMethod;

export type MainPaymentMethod = CardPaymentMethod | BankTransferPaymentMethod;
export type SecondaryPaymentMethod = Exclude<PaymentMethod, MainPaymentMethod>;

export const isMainPaymentMethod = (
  value: PaymentMethod,
): value is MainPaymentMethod =>
  value.type === 'card' || value.type === 'bank_transfer';

export const isSecondaryPaymentMethod = (
  value: PaymentMethod,
): value is SecondaryPaymentMethod => !isMainPaymentMethod(value);
