import { API_URL } from '@absdepot/data';
import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { AuthStatus } from './auth-status';

export const BYPASS_APPEND_AUTH_TOKEN = new HttpContextToken(() => false);

export const appendAuthToken: HttpInterceptorFn = (req, next) => {
  const apiUrl = inject(API_URL);
  const auth = inject(AuthenticationService);
  const status$ = toObservable(auth.status);

  if (req.context.get(BYPASS_APPEND_AUTH_TOKEN)) {
    return next(req);
  }

  if (!req.url.startsWith(apiUrl)) {
    return next(req);
  }

  if (Boolean(auth.token())) {
    return next(
      req.clone({
        headers: req.headers.set('Authorization', `Bearer ${auth.token()}`),
      }),
    );
  }
  return status$.pipe(
    filter(
      (status) =>
        status !== AuthStatus.Loading && status !== AuthStatus.Waiting,
    ),
    map(() => {
      if (auth.token()) {
        const headers = req.headers.set(
          'Authorization',
          `Bearer ${auth.token()}`,
        );
        return req.clone({ headers });
      }
      return req;
    }),
    switchMap((req) => next(req)),
  );
};
