/**
 * The status of the service
 */
export const AuthStatus = {
  /**
   * The service is checking if the token in storage is valid.
   */
  Waiting: 'waiting',

  /**
   * Logging In failed with an error.
   */
  Error: 'error',

  /**
   * Attempting to login.
   */
  Loading: 'loading',

  /**
   * The user is signed in.
   */
  Authenticated: 'authenticated',

  /**
   * The user is not signed in.
   */
  NotAuthenticated: 'not_authenticated',
} as const;

export type AuthStatus = (typeof AuthStatus)[keyof typeof AuthStatus];
