import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { PersistentStorage } from './persistent-storage.interface';

export const Persistence = new InjectionToken<PersistentStorage>(
  'persistent storgage',
);

export const providePersistence = (value: PersistentStorage) => {
  return makeEnvironmentProviders([
    {
      provide: Persistence,
      useValue: value,
    },
  ]);
};
